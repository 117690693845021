<template>
  <section id="souvenir">
    <v-card color="#f18750" class="height-souvenir">
      <h1 class="text-center pt-5 header-title">{{ $t("souvenirs") }}</h1>
      <!-- <v-divider
        class="border-opacity-100 mx-auto line-divider"
        style="margin-bottom: 20px"
        color="#ffffff"
      ></v-divider> -->

      <v-container>
        <Flickity ref="flickitySouvenirs" :options="flickityOptions">
          <div
            class="col-lg-6 col-md-6 col-12"
            v-for="(item, i) in item"
            :key="i"
            @click="
              openDialog(item.title, item.subTitle, item.pathImage, item.content,item.redirectPath)
            "
          >
            <v-card style="background-color: #1b4883; border-radius: 20px">
              <v-row class="ma-0 px-0 py-3">
                <v-col
                  class="mx-auto"
                  style="justify-content: center; display: grid"
                >
                  <v-img
                    :src="item.pathImage"
                    lazy-src="https://picsum.photos/id/11/10/6"
                    height="400"
                    width="230"
                    style="border-radius: 20px"
                  >
                  </v-img>
                </v-col>
                <v-col>
                  <h2 style="color: #ffffff">{{ item.title }}</h2>
                  <p style="color: #ffffff">{{ item.subTitle }}</p>

                  <p
                    style="
                      bottom: 10px;
                      right: 30px;
                      position: absolute;
                      color: #ffffff;
                      font-size: 16px;
                      font-weight: bold;
                    "
                  >
                    Read...
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </Flickity>
      </v-container>
    </v-card>
    <v-overlay :value="loading" style="z-index: 9999">
      <v-progress-circular indeterminate :size="64"> </v-progress-circular>
    </v-overlay>

    <v-dialog
      v-model="dialogSouvenirs"
      fullscreen
      hide-overlay
      persistent
      style="background-color: #faf7f8"
    >
      <v-card>
        <div class="py-2">
          <v-btn
            icon
            dark
            @click="closeDialog()"
            color="#1b4883"
            large
            style="right: 0; position: absolute"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <h1
          class="text-center pt-5 header-title"
          style="color: #1b4883 !important"
        >
          {{ $t("souvenirs") }}
        </h1>
        <v-divider
          class="border-opacity-100 mx-auto line-divider"
          color="#1b4883"
        ></v-divider>

        <v-container>
          <v-row class="ma-0 px-0 py-3" align="top" justify="center">
            <v-col cols="12" md="5" class="mx-auto souvenirs-display">
              <v-img
                lazy-src="https://picsum.photos/id/11/10/6"
                :src="pathImage"
                height="500"
                width="370"
                style="border-radius: 20px"
              >
              </v-img>
            </v-col>
            <v-col cols="12" md="7" class="mt-5" style="padding-left: 20px;">
              <h2 style="color: #1b4883" class="my-2">{{ title }}</h2>
              <p style="color: #1b4883" class="my-5">{{ subTitle }}</p>
              <p style="color: #1b4883" v-html="detail" class="my-2"></p>
              <div style="text-align: center" class="my-5">
                <v-btn
                  color="#f18750"
                  rounded
                  dark
                  class="text-center"
                  style="width: 140px"
                  @click="redirectLink(redirect)"
                  >{{ $t("order") }}</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </section>
</template>
    
    <script>
import Flickity from "vue-flickity";
import Service from "@/service/axios-service";
export default {
  name: "ServiceSection",
  data() {
    return {
      flickityOptions: {
        prevNextButtons: true,
        pageDots: false,
        groupCells: true,
      },
      item: [],
      model: null,
      dialogSouvenirs: false,
      title: "",
      subTitle: "",
      pathImage: "",
      detail: "",
      loading: false,
      redirect: "",
    };
  },
  mounted() {
    this.getSouvenirs();
  },
  methods: {
    redirectLink(path) {
      window.open(path, "_blank");
    },
    getSouvenirs() {
      const language = localStorage.getItem("language") || "TH";

      Service.get(`souvenir?Language=${language}`).then((res) => {
        this.item = res.data.data;
        setTimeout(() => {
          this.$refs.flickitySouvenirs.rerender();
        }, 100);
      });
    },
    closeDialog() {
      this.title = "";
      this.subTitle = "";
      this.pathImage = "";
      this.detail = "";
      this.redirect = "";
      this.dialogSouvenirs = false;
    },
    openDialog(title, subTitle, pathImage, detail, redirect) {
      this.title = title;
      this.subTitle = subTitle;
      this.pathImage = pathImage;
      this.detail = detail;
      this.redirect = redirect;

      this.loading = true;
      this.dialogSouvenirs = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
  components: {
    Flickity,
  },
};
</script>
  
<style>
.carousel-cell {
  width: 100%;
  height: 200px;
  margin-right: 10px;
  background: #8c8;
  border-radius: 5px;
  counter-increment: gallery-cell;
}
.souvenirs-display {
  justify-content: end;
  display: grid;
}
/* cell number */
.carousel-cell:before {
  display: block;
  text-align: center;
  content: counter(gallery-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}
.height-souvenir {
  height:700px;
}
.flickity-prev-next-button.previous {
  left: -5%;
  top: 210px;
}
.flickity-prev-next-button.next {
  right: -5%;
  top: 210px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .souvenirs-display {
    justify-content: center !important;
  }
  .md-display {
    justify-content: center;
    display: grid;
  }
  .flickity-prev-next-button.previous {
    left: 10% !important;
  }
  .flickity-prev-next-button.next {
    right: 10% !important;
  }
  .height-souvenir {
  height:750px;
}
}

@media (max-width: 767.98px) {
  .souvenirs-display {
    justify-content: center !important;
  }
  .flickity-prev-next-button.previous {
    left: -10px;
  }
  .flickity-prev-next-button.next {
    right: -10px;
  }
  .height-souvenir {
  height:750px;
}
}
</style>
    