
<template>
  <section id="story">
    <v-card
      min-height="850px"
      color="#21467e"
      :height="isXs ? 'auto' : '850px'"
    >
      <v-row class="pa-0 ma-0">
        <v-col cols="12" md="6" lg="6">
          <div>
            <h1
              class="text-center pt-5 header-title"
              style="color: white !important"
            >
            {{
              $t("kinlum")
            }}
          </h1>
          </div>
          <div class="disclaimer-outer" v-if="!isXs">
            <div class="disclaimer-inner">
              <div
                style="
                  margin-right: 0px;
                  float: none;
                  margin-bottom: 0px;
                  color: #ffffff;
                "
              >
                <p
                  style="padding: 50px 50px 0px 50px; text-align: center"
                  v-html="story"
                ></p>
              </div>
            </div>
          </div>
          <div v-else>
            <p
              style="padding: 50px 50px 0px 50px; text-align: center"
              v-html="story"
            ></p>
          </div>

          <!-- <p v-html="story.descriptionHtml"></p> -->
        </v-col>
        <v-col cols="12" md="6" lg="6" class="ma-0 pa-0">
          <v-carousel
            :height="isXs ? '850px' : '850px'"
            :show-arrows="false"
            cycle
            hide-delimiter-background
            class="cssClass"
          >
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.pathImage"
            ></v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-card>
  </section>
</template>
    
    <script>
import Service from "../service/axios-service";
export default {
  name: "StorySection",
  data() {
    return {
      items: [],
      story: "",
      isXs: false,
    };
  },
  created() {
    this.getBanner();
    setTimeout(() => {
      this.story = JSON.parse(localStorage.getItem("story"));
    }, 1000);
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    getBanner() {
      Service.get("/story")
        .then((res) => {
          this.items = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onResize() {
      this.isXs = window.innerWidth <= 1260;
    },
  },
};
</script>
<style>
.disclaimer-outer {
  background-color: trasparent;
  overflow: hidden;
}
.disclaimer-inner {
  animation: scroll-up 18s linear 0s infinite normal none running;
}
/* Move it (definisce l'animazione) */
@keyframes scroll-up {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(-100%);
  }
}
/* Mette in pausa l'animazione al passaggio del mouse */
.disclaimer-inner:hover {
  cursor: pointer;
  animation-play-state: paused;
}
button.v-carousel__controls__item.v-btn.v-item--active.v-btn--active.v-btn--icon.v-btn--round.theme--dark.v-size--small {
  color: gray !important;
  background-color: gray !important;
}
.cssClass .v-btn {
  color: white !important;
  background-color: white !important;
  width: 10px;
  height: 10px;
}
i.v-icon.notranslate.mdi.mdi-circle {
  color: transparent !important;
}
</style>
  
    