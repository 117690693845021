<template>
  <section id="menu">
    <v-card color="#1A4883" >
      <v-container class="menu-section">
        <h1
          class="text-center header-title"
          
          style="color: #0c3e5c !important;padding-top: 50px;"
        >
          {{ $t("menu") }}
        </h1>
        <!-- <v-divider
          class="border-opacity-100 mx-auto line-divider"
          color="#0c3e5c"
        ></v-divider> -->

        <v-row class="mt-2">
          <v-col
            cols="6"
            lg="4"
            md="4"
            v-for="(item, i) in item"
            :key="i"
            @click="openDialog(item.title, item.detail)"
            justify="center"
            style="display: grid"
            class="mt-2"
          >
            <v-img
              :src="item.pathImage"
              lazy-src="https://picsum.photos/id/11/10/6"
              height="150"
              width="150"
              class="mx-auto"
            >
            </v-img>
          </v-col>
        </v-row>
        <v-row class="mt-5 my-5" justify="center" style="padding-bottom: 20px;">
          <v-col
            cols="6"
            lg="4"
            md="4"
            v-for="(item, i) in category"
            :key="i"
            justify="center"
            style="display: grid"
            class="mt-2"
          >
            <v-img
              :src="item.pathImage"
              lazy-src="https://picsum.photos/id/11/10/6"
              height="150"
              width="150"
              style="border-radius: 50%"
              class="mx-auto"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog
      v-model="dialogMenu"
      fullscreen
      hide-overlay
      persistent
      style="background-color: #faf7f8"
    >
      <v-card>
        <div class="py-2">
          <v-btn
            icon
            dark
            @click="closeDialog()"
            color="#0c3e5c"
            large
            style="right: 0; position: absolute"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <h1 class="text-center pt-5 header-title" style="color: #0c3e5c">
          {{ title ?? "title" }}
        </h1>
        <v-divider
          class="border-opacity-100 mx-auto line-divider"
          color="#0c3e5c"
        ></v-divider>

        <v-container>
          <Flickity ref="flickityMenuDetail" :options="flickityOptions">
            <div
              class="col-lg-4 col-md-4 col-12 md-detail-display"
              style="justify-content: center; display: grid"
              v-for="(item, i) in detail"
              :key="i"
            >
              <v-img
                lazy-src="https://picsum.photos/id/11/10/6"
                :src="item.pathImage"
                height="490"
                width="350"
                style="border-radius: 20px"
              >
              </v-img>
            </div>
          </Flickity>
        </v-container>
      </v-card>
    </v-dialog>
    <v-overlay :value="loading" style="z-index: 9999">
      <v-progress-circular indeterminate :size="64"> </v-progress-circular>
    </v-overlay>
  </section>
</template>
      
      <script>
import Service from "@/service/axios-service";
import Flickity from "vue-flickity";

export default {
  name: "MenuSection",
  data() {
    return {
      dialogMenu: false,
      flickityOptions: {
        prevNextButtons: true,
        pageDots: false,
        groupCells: true,
      },
      detail: [],
      item: [],
      loading: false,
      category: [],
    };
  },
  created() {
    this.getMenuData();
    this.getCategoryData();
  },
  components: {
    Flickity,
  },
  methods: {
    getCategoryData() {
      Service.get("/menu")
        .then((res) => {
          this.category = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openDialog(title, detail) {
      this.title = title;
      this.detail = detail;
      this.loading = true;
      this.dialogMenu = true;
      setTimeout(() => {
        this.$refs.flickityMenuDetail.rerender();
        this.loading = false;
      }, 1000);
    },
    closeDialog() {
      this.dialogMenu = false;
    },
    getMenuData() {
      const language = localStorage.getItem("language") || "TH";

      Service.get(`/categories?Language=${language}`)
        .then((res) => {
          this.item = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    
  <style>
.menu-section {
  max-width: 850px;
  background-color: rgb(250, 247, 248);
  padding: 1px 175px;
  border-radius: 0px !important;
  
}
.carousel-cell {
  width: 100%;
  height: 200px;
  margin-right: 10px;
  background: #8c8;
  border-radius: 5px;
  counter-increment: menu-cell;
}

/* cell number */
.carousel-cell:before {
  display: block;
  text-align: center;
  content: counter(menu-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}
.flickity-prev-next-button.previous {
  left: -5%;
  top: 210px;
}
.flickity-prev-next-button.next {
  right: -5%;
  top: 210px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .md-detail-display {
    justify-content: center;
    display: grid;
  }
  .md-display {
    justify-content: center;
    display: grid;
  }
  .flickity-prev-next-button.previous {
    left: 10% !important;
  }
  .flickity-prev-next-button.next {
    right: 10% !important;
  }
}

@media (max-width: 767.98px) {
  .menu-section {
    max-width: 850px;
    padding: 45px !important ;
  }
  .md-detail-display {
    justify-content: center;
    display: grid;
  }
  .flickity-prev-next-button.previous {
    left: -10px;
  }
  .flickity-prev-next-button.next {
    right: -10px;
  }
}
</style>
      