var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"contact"}},[_c('v-card',{staticClass:"pt-5",attrs:{"color":"#21467e"}},[_c('h1',{staticClass:"text-center pt-5 header-title"},[_vm._v(_vm._s(_vm.$t("contact")))]),_c('v-container',[_c('v-row',{staticClass:"ma-0 px-0 py-3",attrs:{"align":"top","justify":"center"}},[_c('v-col',{staticClass:"mx-auto",staticStyle:{"padding-right":"15px"},attrs:{"cols":"12","md":"6"}},[_c('v-img',{staticStyle:{"border-radius":"20px"},attrs:{"lazy-src":"https://picsum.photos/id/11/10/6","src":_vm.item.pathImage,"height":"500"}})],1),_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"6"}},[_c('v-form',{ref:"form"},[_c('div',[_c('h3',{staticClass:"my-1",staticStyle:{"color":"white"}},[_vm._v("ชื่อ/Name*")]),_c('v-text-field',{staticClass:"w-100",attrs:{"solo":"","dense":"","rules":[(v) => !!v || 'This field is required']},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('div',[_c('h3',{staticClass:"my-1",staticStyle:{"color":"white"}},[_vm._v("อีเมล/Email*")]),_c('v-text-field',{staticClass:"w-100",attrs:{"solo":"","dense":"","rules":[
                  (v) => !!v || 'This field is required',
                  (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',[_c('h3',{staticClass:"my-1",staticStyle:{"color":"white"}},[_vm._v("เบอร์ติดต่อ/Phone*")]),_c('v-text-field',{staticClass:"w-100",attrs:{"solo":"","dense":"","rules":[
                  (v) => !!v || 'This field is required',
                  (v) =>
                    /^[0-9]{10}$/.test(v) || 'Phone number must be valid',
                ]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('div',[_c('h3',{staticClass:"my-1",staticStyle:{"color":"white"}},[_vm._v("ข้อความ/Message*")]),_c('v-textarea',{staticClass:"w-100",attrs:{"solo":"","dense":"","rules":[(v) => !!v || 'This field is required'],"rows":"3"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)]),_c('div',{staticClass:"text-end my-5"},[_c('v-btn',{staticClass:"text-center",staticStyle:{"width":"140px","background-color":"white","color":"#21467e"},attrs:{"rounded":""},on:{"click":_vm.submit}},[_vm._v("SEND")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }