<template>
  <section id="review">
    <v-card height="650px" color="#21467e" class="pt-5">
      <h1 class="text-center header-title">{{ $t("review") }}</h1>
      <!-- <v-divider
        class="border-opacity-100 mx-auto line-divider"
        color="#ffffff"
      ></v-divider> -->

      <v-container>
        <Flickity ref="flickityReview" :options="flickityOptions">
          <div
            class="col-lg-4 col-md-6 col-12 md-display"
            style="justify-content: center; display: grid"
            v-for="(item, i) in item"
            @click="openNewTab(item.redirectPath)"
            :key="i"
          >
            <v-img
              :src="item.pathImage"
              lazy-src="https://picsum.photos/id/11/10/6"
              height="365"
              width="365"
              style="border-radius: 20px"
            >
            </v-img>
            <h2
              style="font-size: 18px; margin-top: 15px;color: white;"
              class="text-center"
           
            >
              {{ item.title }}
            </h2>
          </div>
        </Flickity>
      </v-container>
    </v-card>
  </section>
</template>
    
    <script>
import Service from "@/service/axios-service";
import Flickity from "vue-flickity";

export default {
  name: "ServiceSection",
  data() {
    return {
      flickityOptions: {
        prevNextButtons: true,
        pageDots: false,
        groupCells: true,
      },
      item: [],
      model: null,
      detail: [],
      title: "",
      loading: false,
    };
  },
  components: {
    Flickity,
  },
  mounted() {
    this.getReview();
  },
  methods: {
    openNewTab(url){
      window.open(url, "_blank");
    }
 ,
    getReview() {
      const language = localStorage.getItem("language") || "TH";

      Service.get(`review?Language=${language}`).then((res) => {
        this.item = res.data.data;
        setTimeout(() => {
          this.$refs.flickityReview.rerender();
        }, 100);
      });
    },
  },
  
};
</script>
  
<style>
.carousel-cell {
  width: 100%;
  height: 200px;
  margin-right: 10px;
  background: #8c8;
  border-radius: 5px;
  counter-increment: review-cell;
}

/* cell number */
.carousel-cell:before {
  display: block;
  text-align: center;
  content: counter(review-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}
.flickity-prev-next-button.previous {
  left: -5%;
  top: 210px;
}
.flickity-prev-next-button.next {
  right: -5%;
  top: 210px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .md-detail-display {
    justify-content: center;
    display: grid;
  }
  .md-display {
    justify-content: center;
    display: grid;
  }
  .flickity-prev-next-button.previous {
    left: 10% !important;
  }
  .flickity-prev-next-button.next {
    right: 10% !important;
  }
}

@media (max-width: 767.98px) {
  .md-detail-display {
    justify-content: center;
    display: grid;
  }
  .flickity-prev-next-button.previous {
    left: -10px;
  }
  .flickity-prev-next-button.next {
    right: -10px;
  }
}
</style>
    