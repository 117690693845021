<template>
  <div>
    <v-navigation-drawer v-model="drawer" app temporary color="#f18750" right>
      <div class="ma-5" style="justify-content: center; display: flex">
        <img src="/logo.png" alt="Logo" style="max-width: 150px" />
      </div>

      <v-list dense>
        <v-list-item
          v-for="([text, link], i) in items"
          :key="i"
          link
          @click="$vuetify.goTo(link)"
        >
          <v-list-item-content>
            <v-list-item-title class="subtitile-1 text-center mt-3">{{
              $t(text)
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <div class="text-center mt-2">
          <v-col
            cols="12"
            v-for="(icon, i) in icons"
            :key="i"
            class="pa-1 mt-4"
          >
            <v-btn icon class="mx-2" :href="icon.redirectPath">
              <img :src="icon.pathImage" style="width: 25px" />
            </v-btn>
          </v-col>

          <div class="mt-2">
            <v-btn
              :color="lang == 'TH' ? '#21467e' : '#000000'"
              text
              style="font-size: large"
              @click="changeLang('TH')"
            >
              TH
            </v-btn>
            |
            <v-btn
              :color="lang == 'EN' ? '#21467e' : '#000000'"
              text
              style="font-size: large"
              @click="changeLang('EN')"
            >
              EN
            </v-btn>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      :color="color"
      :flat="flat"
      dark
      class="px-2"
      absolute

      :class="{ expand: flat }"
    >
      <v-toolbar-title style="padding-right: 40px">
        <v-img src="/logo.png" max-width="100px" />
      </v-toolbar-title>

      <v-spacer v-if="isXs"> </v-spacer>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="mr-4"
        v-if="isXs"
      />
      <v-row v-else justify="space-between">
        <div style="align-self: center; width: 75%">
          <div style="justify-content: center; display: flex">
            <v-btn
              text
              @click="$vuetify.goTo('#story')"
              :style="{ textTransform: 'none' }"
            >
              <span style="font-size: 16px; margin: 0px 30px">{{
                $t("story")
              }}</span>
            </v-btn>

            <v-btn
              text
              @click="$vuetify.goTo('#menu')"
              :style="{ textTransform: 'none' }"
            >
              <span style="font-size: 16px; margin: 0px 30px">{{
                $t("menu")
              }}</span>
            </v-btn>

            <v-btn
              text
              @click="$vuetify.goTo('#souvenir')"
              :style="{ textTransform: 'none' }"
            >
              <span style="font-size: 16px; margin: 0px 30px">{{
                $t("souvenirs")
              }}</span>
            </v-btn>
            <v-btn
              text
              @click="$vuetify.goTo('#review')"
              :style="{ textTransform: 'none' }"
            >
              <span style="font-size: 16px; margin: 0px 30px">{{
                $t("review")
              }}</span>
            </v-btn>
            <v-btn
              text
              @click="$vuetify.goTo('#contact')"
              :style="{ textTransform: 'none' }"
            >
              <span style="font-size: 16px; margin: 0px 30px">{{
                $t("contact")
              }}</span>
            </v-btn>
          </div>
        </div>

        <div>
          <v-btn
            icon
            class="mx-1"
            v-for="(icon, i) in icons"
            :key="i"
            :href="icon.redirectPath"
            style="font-size: 25px"
          >
            <img :src="icon.pathImage" style="width: 25px" />
          </v-btn>

          <v-btn
            :color="lang == 'TH' ? '#f18750' : '#ffffff'"
            text
            style="font-size: large"
            @click="changeLang('TH')"
          >
            TH
          </v-btn>
          |
          <v-btn
            :color="lang == 'EN' ? '#f18750' : '#ffffff'"
            text
            style="font-size: large"
            @click="changeLang('EN')"
          >
            EN
          </v-btn>
        </div>
      </v-row>
    </v-app-bar>
  </div>
</template>
  
  <style scoped>
.v-toolbar {
  transition: 0.6s;
 
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}
</style>
  
  <script>
import Service from "@/service/axios-service";
export default {
  name: "NavbarComponent",
  data: () => ({
    drawer: null,
    isXs: false,
    items: [
      ["story", "#story"],
      ["menu", "#menu"],
      ["souvenirs", "#souvenir"],
      ["review", "#review"],
      ["contact", "#contact"],
    ],
    lang: "TH",
    icons: [],
  }),
  props: {
    color: String,
    flat: Boolean,
  },
  created() {
    this.lang = localStorage.getItem("language") || "TH";
    this.getSocial();
  },
  methods: {
    getSocial() {
      Service.get(`/header/social`)
        .then((res) => {
          this.icons = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onResize() {
      this.isXs = window.innerWidth <= 1260;
    },
    changeLang(lang) {
      localStorage.setItem("language", lang);
      this.lang = lang;
      window.location.reload();
    },
  },

  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
  