<template>
  <section  style="pointer-events: none">
       <iframe
      :src="items"
      allow="autoplay"
      style="height: 800px; width: 100%; display: block"
      frameborder="0"
      allowfullscreen
    ></iframe>
   

    <!-- <v-carousel height="100%" show-arrows-on-hover cycle hide-delimiters>
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        src="https://picsum.photos/id/11/10/6"
      ></v-carousel-item>
    </v-carousel> -->
  </section>
</template>
  
  <script>
import Service from "../service/axios-service";
export default {
  name: "HomeSection",
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.getBanner();
  },
  methods: {
    getBanner() {
      Service.get("/banner")
        .then((res) => {
          this.items = res.data.data.pathImage ;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.ytplayer {
}
</style>
  