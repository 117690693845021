<template>
  <section id="contact">
    <v-card color="#21467e" class="pt-5">
      <h1 class="text-center pt-5 header-title">{{ $t("contact") }}</h1>
      <!-- <v-divider
        class="border-opacity-100 mx-auto line-divider"
        color="#ffffff"
      ></v-divider> -->

      <v-container>
        <v-row class="ma-0 px-0 py-3" align="top" justify="center">
          <v-col cols="12" md="6" class="mx-auto" style="padding-right: 15px">
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              :src="item.pathImage"
              height="500"
              style="border-radius: 20px"
            >
            </v-img>
          </v-col>

          <v-col cols="12" md="6" class="mx-auto">
            <v-form ref="form">
              <div>
                <h3 class="my-1" style="color: white">ชื่อ/Name*</h3>
                <v-text-field
                  v-model="name"
                  solo
                  dense
                  :rules="[(v) => !!v || 'This field is required']"
                  class="w-100"
                ></v-text-field>
              </div>
              <div>
                <h3 class="my-1" style="color: white">อีเมล/Email*</h3>
                <v-text-field
                  v-model="email"
                  solo
                  dense
                  :rules="[
                    (v) => !!v || 'This field is required',
                    (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                  ]"
                  class="w-100"
                ></v-text-field>
              </div>
              <div>
                <h3 class="my-1" style="color: white">เบอร์ติดต่อ/Phone*</h3>
                <v-text-field
                  v-model="phone"
                  solo
                  dense
                  :rules="[
                    (v) => !!v || 'This field is required',
                    (v) =>
                      /^[0-9]{10}$/.test(v) || 'Phone number must be valid',
                  ]"
                  class="w-100"
                ></v-text-field>
              </div>
              <div>
                <h3 class="my-1" style="color: white">ข้อความ/Message*</h3>
                <v-textarea
                  v-model="message"
                  solo
                  dense
                  class="w-100"
                  :rules="[(v) => !!v || 'This field is required']"
                  rows="3"
                ></v-textarea>
              </div>
            </v-form>
            <div class="text-end my-5">
              <v-btn
                rounded
                class="text-center"
                style="width: 140px; background-color: white; color: #21467e"
                @click="submit"
                >SEND</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </section>
</template>
    
    <script>
import Service from "@/service/axios-service";
import Swal from "sweetalert2";

export default {
  name: "ServiceSection",
  data() {
    return {
      item: [],
      model: null,
      detail: [],
      name: "",
      email: "",
      phone: "",
      message: "",

      title: "",
      loading: false,
    };
  },

  mounted() {
    this.getReview();
  },
  methods: {
    openNewTab(url) {
      window.open(url, "_blank");
    },
    getReview() {
      const language = localStorage.getItem("language") || "TH";

      Service.get(`contact?Language=${language}`).then((res) => {
        this.item = res.data.data;
      });
    },
    submit() {
      if (this.$refs.form.validate()) {
        const data = {
          name: this.name,
          email: this.email,
          telephone: this.phone,
          message: this.message,
        };
        Service.post(`contact`, data).then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
        });
      }
    },
  },
};
</script>
  
<style>
.carousel-cell {
  width: 100%;
  height: 200px;
  margin-right: 10px;
  background: #8c8;
  border-radius: 5px;
  counter-increment: contact-cell;
}

/* cell number */
.carousel-cell:before {
  display: block;
  text-align: center;
  content: counter(contact-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}
.flickity-prev-next-button.previous {
  left: -5%;
  top: 210px;
}
.flickity-prev-next-button.next {
  right: -5%;
  top: 210px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .md-detail-display {
    justify-content: center;
    display: grid;
  }
  .md-display {
    justify-content: center;
    display: grid;
  }
  .flickity-prev-next-button.previous {
    left: 10% !important;
  }
  .flickity-prev-next-button.next {
    right: 10% !important;
  }
}

@media (max-width: 767.98px) {
  .md-detail-display {
    justify-content: center;
    display: grid;
  }
  .flickity-prev-next-button.previous {
    left: -10px;
  }
  .flickity-prev-next-button.next {
    right: -10px;
  }
}
</style>
    