<template>
  <section id="contact">
    <v-footer dark padless style="background-color: #223f1b">
      <v-container>
        <v-row class="ma-0" style="padding: 25px 0px">
          <v-col cols="12" md="4">
            <div>
              <p style="font-weight: bold">{{               $t("kinlum")
              }} </p>
              <p style="font-weight: bold">{{ address }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div>
              <p style="font-weight: bold">Tel : {{ tel }}</p>
            </div>
            <div>
              <p style="font-weight: bold">E-mail : {{ email }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div>
              <v-row style="justify-content: end; text-align: end">
                <v-col
                  cols="2"
                  class="px-0"
                  v-for="(icon, i) in icons"
                  :key="i"
                  :href="icon.redirectPath"
                  style="max-width: fit-content"
                >
                  <v-btn icon class="mx-1" :href="icon.redirectPath">
                    <img :src="icon.pathImage" style="width: 25px" />
                  </v-btn>
                </v-col>
              </v-row>
              <p class="mt-2" style="font-weight: bold; text-align: end">
                Follow us
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </section>
</template>
  
  <style scoped>
.v-card {
  width: 100%;
}
.footer-text {
  padding-left: 25%;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .footer-text {
    padding-left: 32% !important;
  }
}
@media (max-width: 767.98px) {
  .footer-text {
    padding-left: 15% !important;
  }
}
</style>
  
  <script>
import Service from "@/service/axios-service";
export default {
  name: "FooterSection",
  data: () => ({
    icons: [],
    contact: "",
    address: "",
    tel: "",
    email: "",
  }),
  mounted() {
    // this.getSocial();
    this.getContact();
    this.getSocial();
  },
  methods: {
    getContact() {
      const language = localStorage.getItem("language") || "TH";
      Service.get(`/contact?Language=${language}`)
        .then((res) => {
          this.contact = res.data.data;
          this.address = res.data.data.address;
          this.tel = res.data.data.telephone;
          this.email = res.data.data.email;
          localStorage.setItem("story", JSON.stringify(res.data.data.story));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSocial() {
      Service.get(`/footer/social`)
        .then((res) => {
          this.icons = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
  